const { BESTSELLER_PATH } = require('../constants/paths');
const bestSellerClient = require('../client/restclient')({
  timeout: process.env.NODE_ENV === 'production' ? 5000 : 5000,
  baseURL: '/',
  retry: {
    maxRetries: 1,
    delay: 0,
  },
});

class Service {
  static getBestSellerData({ params, siteId }) {
    const path = siteId === 'MLB' ? '/mais-vendidos' : '/mas-vendidos';
    const url = `${path}${BESTSELLER_PATH}`;

    return bestSellerClient
      .get(url, {
        params,
      })
      .then((response) => response.data);
  }
}

module.exports = Service;
