import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Card, { CardContent, CardHeader, CardFooter } from '@andes/card';

import Layout from './grid-layout';
import { useUser } from '../context/user';

const namespace = 'ui-search-layout--grid';

const ContainerItemsMobile = ({ component, className, preload, preloadNumImage, deviceType, countryConfig }) => {
  const { title, polycards, polycard_context, button_action } = component;
  const { loggedIn, loginUrl } = useUser();

  const redirect = (url) => {
    if (loggedIn) {
      window.location.href = url;

      return;
    }

    window.location.href = `${loginUrl.split('&go=')[0]}&go=${url}&loginType=explicit`;
  };

  return (
    <section
      className={classNames(`${namespace}__container`, {
        [`${className}__layout--grid`]: className,
      })}
    >
      <Card className={classNames(`${namespace}__card`)}>
        {title && (
          <CardHeader>
            <h2 className={classNames(`${namespace}__title`)}>{title}</h2>
          </CardHeader>
        )}
        <CardContent>
          <Layout
            items={polycards}
            context={polycard_context}
            namespace={namespace}
            preload={preload}
            preloadNumImage={preloadNumImage}
            deviceType={deviceType}
            countryConfig={countryConfig}
          />
        </CardContent>
        {button_action && (
          <CardFooter>
            <button
              type="button"
              className="andes-card__button andes-card__footer--link"
              onClick={() => {
                redirect(button_action.url);
              }}
            >
              {button_action.text}
              <span className="andes-card__arrow" />
            </button>
          </CardFooter>
        )}
      </Card>
    </section>
  );
};

ContainerItemsMobile.propTypes = {
  className: PropTypes.string,
  component: PropTypes.shape({
    button_action: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
    polycard_context: PropTypes.shape({}),
    polycards: PropTypes.arrayOf(PropTypes.shape({})),
    recommendations: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string,
  }),
  countryConfig: PropTypes.shape({}),
  deviceType: PropTypes.string,
  preload: PropTypes.bool,
  preloadNumImage: PropTypes.number,
};

export default ContainerItemsMobile;
