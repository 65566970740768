import React, { useReducer } from 'react';
import { node } from 'prop-types';

import { useSearch } from '../../../hooks/context';

const BestSellerContext = React.createContext(null);
const { Provider, Consumer: BestSellerConsumer } = BestSellerContext;

const BestSellerProvider = ({ children }) => {
  const { sidebar, results } = useSearch();
  const initialState = {
    sidebar,
    results,
    load: false,
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'BS_UPDATE-COMPONENTS':
        return { ...state, results: action.payload };
      case 'BS_UPDATE-SIDEBAR':
        return { ...state, sidebar: action.payload };
      case 'BS_UPDATE-LOAD':
        return { ...state, load: !state.load };

      default:
        return state;
    }
  }

  const [bestSellerState, bestSellerDispatch] = useReducer(reducer, initialState);

  return <Provider value={{ bestSellerState, bestSellerDispatch }}>{children}</Provider>;
};

BestSellerProvider.propTypes = {
  children: node.isRequired,
};

export { BestSellerContext, BestSellerProvider, BestSellerConsumer };
