const getAttributeId = (url) => {
  const { query } = new URL(url);

  return query?.attribute_value_id || false;
};

const transformDataSidebar = ({ expanded_values, breadcrumb, currentCategory }) => {
  let maxLevel = 0;

  const breadcrumbFormat = breadcrumb?.nodes?.map(({ id, text, url }, index) => {
    maxLevel = index;

    return {
      id,
      name: text,
      url,
      level: index,
      active: id === currentCategory?.id && !currentCategory?.attribute_value_id,
    };
  });

  const categoriesFormat = expanded_values.map((category) => {
    const attribute_value_id = getAttributeId(category?.url);

    return {
      ...category,
      attribute_value_id,
      level: maxLevel + 1,
      active: attribute_value_id === currentCategory?.attribute_value_id && attribute_value_id,
    };
  });

  return [...breadcrumbFormat, ...categoriesFormat];
};

export default transformDataSidebar;
