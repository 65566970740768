import React, { useMemo, useState } from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';
import { Snackbar } from '@andes/snackbar';

import useCategoryTopMenu from './hooks/useCategoryTopMenu';
import transformDataSidebar from './helpers/transformDataSidebar';
import { RenderList, ToggleButton } from './components';
import { withComponentHandler } from '../../hocs/with-component-handler';

/**
 * @param {string} [props.className=null] - The class name for the component.
 */

const CategoryTopMenuComponent = ({ className = null }) => {
  const { namespace, errorSnack, setErrorSnack, currentCategory, breadcrumb, expanded_values, webView } =
    useCategoryTopMenu();
  const [showMenu, setShowMenu] = useState(false);

  const formatData = useMemo(
    () => transformDataSidebar({ expanded_values, breadcrumb, currentCategory }),
    [currentCategory, breadcrumb, expanded_values],
  );

  return (
    <>
      <section>
        <div
          className={classnames(`${namespace}-content`, `${className}-${namespace}`, {
            [`${className}-${namespace}-webview`]: webView,
          })}
        >
          <ToggleButton showMenu={showMenu} setShowMenu={setShowMenu} />
          <nav className={`${namespace}-content-menu ${!showMenu && 'not-display'} `}>
            <ol className="children-categories">
              {showMenu &&
                formatData.map((category, index) => (
                  <RenderList key={category.category_id} category={category} index={index} setShowMenu={setShowMenu} />
                ))}
            </ol>
          </nav>
        </div>
      </section>
      <Snackbar
        message={errorSnack}
        color="red"
        show={!!errorSnack}
        delay={3000}
        onChange={(snackbarState) => {
          setErrorSnack(snackbarState ? errorSnack : '');
        }}
      />
    </>
  );
};

CategoryTopMenuComponent.propTypes = {
  className: string,
};

export const CategoryTopMenu = withComponentHandler(CategoryTopMenuComponent, { componentName: 'CategoryTopMenu' });
