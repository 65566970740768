import { useState, useEffect, useMemo, useContext } from 'react';

import { useSearch } from '../../../hooks/context';
import { useStaticProps } from '../../../components/context/static-props';
import { BestSellerContext } from '../../../components/context/best-seller';
import { getBestSellerData } from '../../../../services/bestseller';

const getCategoryIdFromUrl = (url) => {
  if (!url) {
    return null;
  }

  const segments = new URL(url).pathname.split('/');

  return segments.pop();
};

const useCategoryTopMenu = () => {
  const namespace = 'category-menu-top';
  const { sidebar } = useSearch();
  const { webView, queryParams, baseURL, siteId } = useStaticProps();
  const { bestSellerState, bestSellerDispatch } = useContext(BestSellerContext) || {};
  const [sidebarState, setSidebarState] = useState(sidebar);
  const [errorSnack, setErrorSnack] = useState('');

  useEffect(() => {
    setSidebarState(bestSellerState?.sidebar || sidebar);
  }, [bestSellerState?.sidebar, sidebar]);

  const breadcrumb = useMemo(
    () => sidebarState?.components?.find((component) => component.type === 'BREADCRUMB'),
    [sidebarState],
  );
  const { filters = [] } = useMemo(
    () => sidebarState?.components?.find((component) => component.type === 'AVAILABLE_FILTERS') || {},
    [sidebarState],
  );
  const { expanded_values = [] } = filters[0] || {};

  const getNameFromAttributeId = (attributeId) =>
    expanded_values.find((category) => category.url.includes(attributeId))?.name;

  const [currentCategory, setCurrentCategory] = useState({
    id: getCategoryIdFromUrl(baseURL),
    attribute_value_id: queryParams?.attribute_value_id,
    name: queryParams?.attribute_value_id && getNameFromAttributeId(queryParams?.attribute_value_id),
  });

  const handleLink = async (category) => {
    const { id, url, active = false } = category;

    if (id && !active) {
      const { query: otherQueries, search } = new URL(url);

      const attributeParams = new URLSearchParams(search);

      const attribute_id = attributeParams.get('attribute_id');
      const attribute_value_id = attributeParams.get('attribute_value_id');

      const { middleware = null, frontend = null } = queryParams || {};
      const params = {
        category_id: id,
        ...otherQueries,
        middleware,
        frontend,
        attribute_id,
        attribute_value_id,
      };

      bestSellerDispatch({ type: 'BS_UPDATE-LOAD' });

      try {
        const data = await getBestSellerData({ params, siteId });

        bestSellerDispatch({ type: 'BS_UPDATE-COMPONENTS', payload: data.components });
        bestSellerDispatch({ type: 'BS_UPDATE-SIDEBAR', payload: data.sidebar });
        setCurrentCategory(category);
      } catch (error) {
        setErrorSnack(error.response?.statusText || 'Error');
      } finally {
        bestSellerDispatch({ type: 'BS_UPDATE-LOAD' });
      }
    }
  };

  return {
    namespace,
    errorSnack,
    setErrorSnack,
    currentCategory,
    breadcrumb,
    expanded_values,
    handleLink,
    webView,
    baseURL,
    bestSellerDispatch,
  };
};

export default useCategoryTopMenu;
