/**
 * Module dependencies
 */
import React from 'react';

import { CategoryTopMenu } from '../../../../../components-v2/category-top-menu';
import CategoryTrendsMobileFactory from '../../../../../components-v2/category-trends-expandable/chunk-category-trends-mobile-factory';
import CategoriesHeader from '../../../../../components-v2/header/categories';
import Category from './main.cat';
import SeoBlogsComponent from '../../../../../components/seo-blog-component';

/**
 * View Component
 */
const SearchMobileCAT = (props) => {
  const namespace = 'ui-category-trends-mobile';

  return (
    <Category {...props}>
      <CategoriesHeader />
      <CategoryTopMenu className={namespace} />
      <section className={`${namespace}-content`}>
        <CategoryTrendsMobileFactory />
      </section>
      <SeoBlogsComponent />
    </Category>
  );
};

/**
 * Inject i18n context as props into View.
 */
export default SearchMobileCAT;
