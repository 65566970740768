import React from 'react';

import { loadable } from 'nordic/lazy';

import { useSearch } from '../../hooks/context';
import { useUser } from '../../components/context/user';
import { useStaticProps } from '../../components/context/static-props';
import ContainerItems from '../../components/grid-layout/container-items.mobile';
import { TYPE_RESULTS } from '../../constants';

const {
  HIGHER_GROWTH_TREND,
  MOST_WANTED_TREND,
  MOST_POPULAR_TREND,
  MANUAL_TRENDS,
  CONSOLIDATED_TRENDS,
  OTHER_FILLED_TRENDS,
  RECOMMENDED_ITEMS_POLYCARDS,
  BEST_SELLERS_POLYCARDS,
} = TYPE_RESULTS;

const renderMobileContent = (props, preload, deviceType, countryConfig) => {
  if (!props) {
    return null;
  }

  const { id, trends_categories } = props;

  const CategoryTrendsExpandable = loadable(async () =>
    import(/* webpackChunkName: "chunk-category-trends-expandable" */ './category-trends-expandable'),
  );

  switch (id) {
    case HIGHER_GROWTH_TREND:
    case MOST_WANTED_TREND:
    case MOST_POPULAR_TREND:
    case MANUAL_TRENDS:
      return <CategoryTrendsExpandable key={`${id}-trend`} trends_categories={trends_categories} preload={preload} />;
    case CONSOLIDATED_TRENDS:
      return <CategoryTrendsExpandable key={`${id}-trend`} trends_categories={trends_categories} />;
    case OTHER_FILLED_TRENDS:
      return <CategoryTrendsExpandable key={`${id}-trend`} trends_categories={trends_categories} />;
    case BEST_SELLERS_POLYCARDS:
    case RECOMMENDED_ITEMS_POLYCARDS:
      return (
        <ContainerItems
          key={`${id}-trend`}
          component={trends_categories}
          preload={preload}
          deviceType={deviceType}
          countryConfig={countryConfig}
          preloadNumImage={3}
        />
      );

    default:
      return null;
  }
};

const CategoryTrendMobileFactory = () => {
  const { results } = useSearch();
  const { loggedIn } = useUser();
  const { deviceType, country } = useStaticProps();

  const mostWantedTrendsProps = results.find((x) => x.id === MOST_WANTED_TREND);
  const higherGrothTrendsProps = results.find((x) => x.id === HIGHER_GROWTH_TREND);
  const mostPopulaTrendsProps = results.find((x) => x.id === MOST_POPULAR_TREND);
  const manualTrendsProps = results.find((x) => x.id === MANUAL_TRENDS);
  const consolidatedTrendsProps = results.find((x) => x.id === CONSOLIDATED_TRENDS);
  const otherFilledTrendsProps = results.find((x) => x.id === OTHER_FILLED_TRENDS);
  const bestSellersItemsProps = results.find((x) => x.id === BEST_SELLERS_POLYCARDS);
  const recommendedItemsProps = results.find((x) => x.id === RECOMMENDED_ITEMS_POLYCARDS);

  const layoutItems = [
    renderMobileContent(mostWantedTrendsProps, true),
    renderMobileContent(higherGrothTrendsProps, false),
    renderMobileContent(mostPopulaTrendsProps, false),
    renderMobileContent(consolidatedTrendsProps),
    renderMobileContent(manualTrendsProps, false),
  ];
  const dependantLayoutItems = [
    renderMobileContent(bestSellersItemsProps, loggedIn, deviceType, country),
    renderMobileContent(recommendedItemsProps, loggedIn, deviceType, country),
  ];
  const bottomLayoutItems = [renderMobileContent(otherFilledTrendsProps)];

  if (!loggedIn) {
    return [...layoutItems, ...dependantLayoutItems, ...bottomLayoutItems];
  }

  return [...dependantLayoutItems, ...layoutItems, ...bottomLayoutItems];
};

export default CategoryTrendMobileFactory;
