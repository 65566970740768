import React from 'react';
import { bool, func } from 'prop-types';

import classnames from 'classnames';

import useCategoryTopMenu from '../hooks/useCategoryTopMenu';
import getIcon from '../../icons';
import { withComponentHandler } from '../../../hocs/with-component-handler';

const RenderToggleButton = ({ showMenu, setShowMenu }) => {
  const { namespace, breadcrumb, currentCategory } = useCategoryTopMenu();
  const {
    label: { text, prefix = null },
  } = breadcrumb;

  return (
    <button type="button" className={`${namespace}-toggle-button`} onClick={() => setShowMenu(!showMenu)} tabIndex={0}>
      {prefix && <span className={`${namespace}-toggle-button-title`}>{prefix}</span>}
      <div className={`${namespace}-toggle-button-category-name`}>
        <span>{currentCategory?.name || text}</span>
        {getIcon({
          iconId: 'chevron_right',
          className: classnames(`${namespace}-icon ${showMenu ? 'up' : 'down'}`),
        })}
      </div>
    </button>
  );
};

RenderToggleButton.propTypes = {
  setShowMenu: func.isRequired,
  showMenu: bool.isRequired,
};

export const ToggleButton = withComponentHandler(RenderToggleButton, { componentName: 'RenderToggleButton' });
