import React from 'react';
import { bool, func, number, oneOfType, shape, string } from 'prop-types';

import classnames from 'classnames';

import useCategoryTopMenu from '../hooks/useCategoryTopMenu';
import { withComponentHandler } from '../../../hocs/with-component-handler';

/**
 * @typedef {Object} Category
 * @property {string|boolean} active - Indicates if the category is active.
 * @property {number} level - The level of the category in the hierarchy.
 * @property {string} category_id - The unique identifier of the category.
 * @property {string} name - The name of the category.
 * @property {string} url - The URL of the category.
 */

/**
 * RenderListComponent
 *
 * @param {Object} props
 * @param {Category} props.category - The category object.
 * @param {number} props.index - The index of the category in the list.
 * @param {Function} props.setShowMenu - Function to set the visibility of the menu.
 * @returns {JSX.Element}
 */
const RenderListComponent = ({ category, index, setShowMenu }) => {
  const { active = false, level } = category;
  const { namespace, breadcrumb, handleLink, webView, baseURL, bestSellerDispatch } = useCategoryTopMenu();

  const isActive = baseURL === undefined ? index === breadcrumb.nodes.length - 1 : false;

  const elementClass = classnames(
    `${namespace}-content-menu-element-${active || isActive ? 'active' : 'not-active'}`,
    `${namespace}-content-menu-level-${level}`,
  );

  const handleButton = () => {
    handleLink(category);
    setShowMenu(false);
  };

  return (
    <li className={elementClass}>
      {webView && bestSellerDispatch ? (
        <button className={`${namespace}-name-button`} onClick={handleButton} type="button">
          {category?.name}
        </button>
      ) : (
        <a href={category?.url}> {category?.name} </a>
      )}
    </li>
  );
};

RenderListComponent.propTypes = {
  category: shape({
    active: oneOfType([string, bool]),
    level: number,
    category_id: string,
    name: string,
    url: string,
  }).isRequired,
  index: number,
  setShowMenu: func,
};

export const RenderList = withComponentHandler(RenderListComponent, { componentName: 'RenderLi' });
